
import {Options, Vue} from "vue-class-component";
@Options({
  components: {}
})
export default class Empresa extends Vue{
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    document.title = 'Volty Empresa'
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  goTo(url: any){
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.open(url, '_blank').focus();
  }
}
