
import {Options, Vue} from "vue-class-component";
@Options({
  components: {}
})
export default class Termo extends Vue{
  mounted() {
    document.title = 'Termos Gerais'
  }
}
