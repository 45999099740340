
import { Options, Vue } from "vue-class-component";
@Options({
  components: {}
})
export default class TermosDeUsoMarketplace extends Vue {
  mounted() {
    document.title = 'Termos de Uso Marketplace de Tarifas';
  }
}
