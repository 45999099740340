
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class TermoUso extends Vue {
  mounted() {
    document.title = 'Termo de Uso Voltynho';
  }
}
