
import {Options, Vue} from "vue-class-component";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
@Options({
  components: {}
})
export default class  PeD extends Vue{

  slide = 'cap_mobile';

  viewSocilictarInfo = false;

  formulario = {
    projeto: '',
    nome: '',
    telefone: '',
    email: '',
    mensagem: ''
  }

  projetos = [{name: 'cap', label: 'CAP Mobile'}, {name: 'perdas', label: 'Perdas Estocásticas'}, {name: 'calculador', label: 'Calculador FID'}];

  loading = false;

  private axiosInstace = axios.create({
    baseURL: 'https://o0riks5xb7.execute-api.us-east-1.amazonaws.com/dev/'
  });

  validEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  async showSolicitarInfo(args: any){
    this.formulario.projeto = args.name;
    this.viewSocilictarInfo = true;
    await this.$nextTick();
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
  }


  onSubmit() {
    this.loading = true;
    const refForm: any = this.$refs.form;
    return new Promise(
        () => {
          this.axiosInstace.post('studioapp/contato', this.formulario).then(
              (resp) => {
                this.formulario = {
                  projeto: '',
                  nome: '',
                  telefone: '',
                  email: '',
                  mensagem: ''
                };
                this.loading = false;
                refForm.reset();
                if(resp.data.status === 'ok'){
                  Notify.create({
                    message: 'Solicitação de Informações enviada !',
                    color: 'positive'
                  })
                }
              }
          )
        });
  }


  mounted() {
    document.title = 'P&D'
  }
}
