import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../pages/Home.vue'
import Trade from "@/pages/Trade.vue";
import PeD from "@/pages/PeD.vue";
import Termo from "@/pages/Termo.vue";
import TermoVoltyPlus from "@/pages/TermoVoltyPlus.vue"
import TermoVoltynho from "@/pages/TermoVoltynho.vue"
import TermoVoltyHydro from "@/pages/TermoHydro.vue"
import Empresa from "@/pages/Empresa.vue";
import Hydro from "@/pages/Hydro.vue";
import TermosDeUsoMarketplace from "@/pages/TermoMarketplace.vue";
import TermosDeUsoEmpresa from "@/pages/TermoEmpresa.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trade',
    name: 'Trade',
    component: Trade
  },
  {
    path: '/ped',
    name: 'P&D',
    component: PeD
  },
  {
    path: '/termo',
    name: 'Termo',
    component: Termo,
  },
  {
    path: '/termo/volty',
    name: 'Volty Plus',
    component: TermoVoltyPlus
  },
  {
    path: '/termo/voltynho',
    name: 'Voltynho',
    component: TermoVoltynho
  },
  {
    path: '/termo/hydro',
    name: 'Volty Hydro',
    component: TermoVoltyHydro
  },
  {
    path: '/termo/volty_marketplace',
    name: 'Marketplace de Tarifas',
    component: TermosDeUsoMarketplace
  },
  {
    path: '/termo/empresa',
    name: 'EmpresaTermo',
    component: TermosDeUsoEmpresa
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: Empresa
  },
  {
    path: '/hydro',
    name: 'Hydro',
    component: Hydro
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
