
import {Options, Vue} from "vue-class-component";

@Options({
  components: {}
})
export default class Trade extends Vue{
  mounted() {
    document.title = 'Volty Trade'
  }
}
