
import { Options, Vue } from "vue-class-component";

@Options({
  components: {}
})
export default class TermosDeUsoEmpresa extends Vue {
  mounted() {
    document.title = 'Termos de Uso Volty Empresa';
  }
}
