
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';;
@Options({
  components: {}
})
export default class Home extends Vue {


  formulario = {
    nome: '',
    telefone: '',
    email: '',
    mensagem: ''
  }

  loading = false;

  private axiosInstace = axios.create({
    baseURL: 'https://o0riks5xb7.execute-api.us-east-1.amazonaws.com/dev/'
  });

  validEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  onSubmit() {
    this.loading = true;
    const refForm: any = this.$refs.form;
    return new Promise(
        () => {
          this.axiosInstace.post('studioapp/contato', this.formulario).then(
              (resp) => {
                this.formulario = {
                  nome: '',
                  telefone: '',
                  email: '',
                  mensagem: ''
                };
                this.loading = false;
                refForm.reset();
                if(resp.data.status === 'ok'){
                  Notify.create({
                    message: 'Solicitação de Contato enviada !',
                    color: 'positive'
                  })
                }
              }
          )
        });
  }

  mounted() {
    if( document['referrer'] === 'https://volty.com.br/' ) {
      this.SmoothScroll('#portfolio')
    }
    document.title = 'studioApp'
  }


  expertObjs = [
    {
      img: require('../assets/home/nuvem.png'),
      titulo: 'APLICAÇÕES EM NUVEM',
      text: 'Garantia de que os servidores estão sempre disponíveis, com backup atualizado e toda segurança necessária para que os clientes não precisem se incomodar com nada.'
    },
    {
      img: require('../assets/home/sismulti.png'),
      titulo: 'SISTEMAS MULTIPLATAFORMA',
      text: 'Projetados para funcionar em computadores, smartphones e tablets com grande facilidade e usabilidade.'
    },
    {
      img: require('../assets/home/dashboard.png'),
      titulo: 'DASHBOARDS',
      text: 'Painéis com indicadores importantes ao controle e gestão dos dados, possibilitando aos decisores acessar informações e acompanhar a evolução dos números sem a necessidade de geração e leitura de relatórios.'
    },
    {
      img: require('../assets/home/chatbot.png'),
      titulo: 'CHAT BOTS',
      text: 'Software para trabalhar e gerenciar trocas de mensagens. Pode ser adaptado em sites, usado através de app próprio ou em conjunto com aplicativos populares, como o Facebook, Messenger ou Telegram.'
    },
    {
      img: require('../assets/home/cienciadados.png'),
      titulo: 'CIÊNCIA DE DADOS',
      text: 'Transformamos grandes quantidades de dados brutos em insights de negócios, e com isso, auxiliamos empresas no processo de tomada de decisão afim de atingir melhores resultados.'
    },
    {
      img: require('../assets/home/bigdata.png'),
      titulo: 'BIG DATA',
      text: 'Softwares capazes de tratar grandes quantidades de dados para transformá-los em informações úteis às organizações. Ideal para explorar novas possibilidades e descobrir novos padrões.'
    },
    {
      img: require('../assets/home/devagil.png'),
      titulo: 'DESENVOLVIMENTO ÁGIL',
      text: 'Metodologias utilizadas no desenvolvimento de software de forma a providenciar uma estrutura conceitual para reger projetos e torná-los mais ágeis'
    }]

  portObjs = [
    {
      img: require('../assets/home/logovoltynho.png'),
      img_size: 78,
      titulo: 'BOT INTERATIVO COM INFORMAÇÕES DO SETOR ELÉTRICO BRASILEIRO',
      btn: true,
      link: 'https://voltynho.com.br/'
    },
    {
      img: require('../assets/home/voltylogo.png'),
      img_size: 200,
      titulo: 'PLATAFORMA DE DADOS SOBRE O SETOR ELÉTRICO BRASILEIRO',
      btn: true,
      link: 'https://volty.com.br/'
    },
    {
      img: require('../assets/termo/empresa.png'),
      img_size: 92,
      rounded: 'roundedImg',
      titulo: 'APLICATIVO MOBILE PARA CONSULTA DE DADOS PESSOA JURÍDICA',
      btn: true,
      link: '/empresa'
    },
    {
      img: require('../assets/termo/hydro.png'),
      img_size: 92,
      rounded: 'roundedImg',
      titulo: 'ACESSE DADOS DE USINAS HIDRELÉTRICAS.',
      btn: true,
      link: '/hydro'
    },
    {
      img: require('../assets/home/pedlogo.png'),
      img_size: 158,
      titulo: 'PROJETOS DE PESQUISA E DESENVOLVIMENTO',
      btn: true,
      link: '/ped'
    },
    {
      img: require('../assets/trade/trade.png'),
      img_size: 142,
      titulo: 'SISTEMAS DE GESTÃO DE CONTRATOS DE COMPRA E VENDA',
      btn: true,
      link: '/trade'
    },
    {
      img: require('../assets/home/voltylablogo.png'),
      img_size: 142,
      titulo: 'SISTEMA DE INTELIGÊNCIA COMPUTACIONAL NA PREVISÃO DE SÉRIES TEMPORAIS',
      btn: false
    },
    {
      img: require('../assets/home/voltypricelogo.png'),
      img_size: 142,
      titulo: 'SISTEMA P/ GERENCIAMENTO DE RODADAS DOS MODELOS DE DESPACHO HIDROTÉRMICO',
      btn: false
    }
  ]

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  SmoothScroll = (selector: any) => {
    document.querySelector(selector).scrollIntoView({behavior: 'smooth'});
  }
}
